/* You can add global styles to this file, and also import other style files */
body {
  margin: 0;

  font-size: 90%;
  .p-component {
    font-size: 90%;
  }
  .p-badge {
    font-size: 0.75rem;
  }
}

.container {
  margin: 10px;
}

.navbar {
  padding: 10px;
  background-color: #58656e;

  .logo {
    font-size: 1.5em;
    font-weight: bold;
    color: white;

    .subtitle {
      font-size: 80%;
      font-weight: normal;
    }
  }

  .nav-button {
    float: right;
    margin-left: 5px;

    .p-button-label {
      font-size: 90%;
    }
  }
}

.table-caption {
  .right {
    float: right;
  }
}

// https://www.colordic.org/colorscheme/2079
app-home {
  .p-card {
    background-color: #4500d9;
    color: white;
  }
}

.hide-table-row {
  display: none;
}

.btn-full {
  .p-button {
    width: 100%;
    border: none;
    cursor: pointer;
    text-align: center;
  }
}

.input-full {
  width: 100%;

  button {
    width: 100%;
  }

  .ui-dropdown {
    width: 100% !important;
  }
}

.full-height {
  height: 100%;
}

.center {
  text-align: center;
}

.icon-big {
  font-size: 3em !important;
}

.clickable {
  cursor: pointer;
}

// for split button
.ui-button.ui-splitbutton-menubutton {
  padding-top: 1px;
  padding-bottom: 1px;
}

.p-tag-default {
  p-tag {
    background-color: lightgray;
  }
}

.table-header-sticky {
  font-size: 90%;

  .p-datatable-wrapper {
    overflow: visible !important;
  }

  @media (min-width: 800px) {
    th,
    td {
      word-break: break-all;
    }
  }
  thead,
  th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

.unpublic {
  background-color: darkgray !important;
}

.p-chip.p-chip-success {
  background: var(--primary-color);
  color: var(--primary-color-text);
}

.product-table {
  .table-img {
    width: 75px;
    max-width: 75px;

    img {
      width: 50px;
      max-width: 50px;
    }
  }

  .asin {
    width: 150px;
    max-width: 150px;
  }

  .number {
    width: 75px;
    max-width: 75px;
  }

  .price {
    width: 75px;
    max-width: 75px;
  }

  .date {
    width: 100px;
    max-width: 100px;
  }

  .notice {
    width: 180px;
    max-width: 180px;
  }

  .bookmark {
    width: 100px;
    max-width: 100px;
  }

  .link {
    width: 90px;
    max-width: 90px;

    a {
      display: inline-flex;
    }
  }

  .icon {
    width: 30px;
    height: 30px;
  }

  .bookmarkable {
    color: white;

    // https://qiita.com/NoxGit/items/eb0904822c0f0fe97650
    text-shadow:
      black 1px 1px 0,
      black -1px -1px 0,
      black -1px 1px 0,
      black 1px -1px 0,
      black 0px 1px 0,
      black (0 - 1px) 0,
      black -1px 0 0,
      black 1px 0 0; /*上、下*/
  }
}

@media screen and (max-width: 70em) {
  .mv-text-container {
    white-space: normal !important;
    text-overflow: initial !important;
    overflow: auto !important;
  }

  .ui-table table {
    width: 99%;
  }

  .ui-table-responsive .ui-table-thead > tr > th,
  .ui-table-responsive .ui-table-tfoot > tr > td {
    display: none !important;
  }

  .ui-table-responsive .ui-table-tbody > tr > td {
    text-align: left !important;
    display: block !important;
    border: 0 none !important;
    width: 100% !important;
    -webkit-box-sizing: border-box !important;
    -moz-box-sizing: border-box !important;
    box-sizing: border-box !important;
    float: left !important;
    clear: left !important;
  }

  .ui-table-responsive .ui-table-tbody > tr > td .ui-column-title {
    padding: 0.4em !important;
    min-width: 30% !important;
    display: inline-block !important;
    margin: -0.4em 1em -0.4em -0.4em !important;
    font-weight: bold !important;
  }

  tr > td {
    text-align: left !important;
    border: 0px !important;
    padding-left: 15px !important;
  }

  tr > th {
    border: 0px !important;
    text-align: left !important;
  }

  tr {
    border: 1px solid #d5d5d5 !important;
  }

  tr > td:last-child {
    padding-bottom: 15px !important;
    margin-bottom: 0 !important;
  }

  tr > td:first-child {
    padding-top: 15px !important;
  }
}

:host ::ng-deep .p-datatable .p-datatable-thead > tr > th {
  position: -webkit-sticky;
  position: sticky;
  top: 69px;
}

@media screen and (max-width: 64em) {
  :host ::ng-deep .p-datatable .p-datatable-thead > tr > th {
    top: 99px;
  }
}

:host ::ng-deep {
  .p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
    display: none;
  }
}

@media screen and (max-width: 40rem) {
  .p-datatable-responsive {
    .p-datatable-thead > tr > th,
    .p-datatable-tfoot > tr > td {
      display: none !important;
    }

    .p-datatable-tbody > tr > td {
      text-align: left;
      display: block;
      width: 100%;
      float: left;
      clear: left;
      border: 0 none;

      .p-column-title {
        padding: 0.4rem;
        min-width: 30%;
        display: inline-block;
        margin: -0.4em 1em -0.4em -0.4rem;
        font-weight: bold;
      }

      &:last-child {
        border-bottom: 1px solid var(--surface-d);
      }
    }
  }
}
